/* Colors */
$white: #ffffff;
$black: #000000;
$lighter-grey: #F7F5F5;
$light-grey: #EEEEEE;
$porcelain: #ECF0F1;
$dark-grey: #4b4b4b;
$light-blue: #C7DFF2;
$pastel-blue: #78A9B4;
$blue: #277796;
$dark-blue: #22313f;
$purple: #333366;
$green: #126C71;