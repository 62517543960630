label.ant-checkbox-group-item {
  width: 160px;
}

.ant-checkbox .ant-checkbox-inner {
  border-color: $blue;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $blue;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $blue;
}

.ant-input:focus, .ant-input:hover,
.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled),
.ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
  border-color: $blue;
}

.ant-calendar-selected-date .ant-calendar-date {
  background: $blue;

  &:focus, &:hover {
    background: $green
  }
}

.ant-calendar-today .ant-calendar-date {
  border-color: $green;
  color: $green;
}