.resource-container {
  padding-top: 20px;
  padding-bottom: 20px;

  .actions {
    margin-bottom: 10px;
  }

  .link-toolbar {
    a {
      margin-right: 5px;
    }
  }

  .label {
    margin-right: 5px;
  }

  .fields {
    ul {
      padding-inline-start: 0;
      list-style-type: none;
    }
  }

  .card {
    border-radius: 10px;
    padding: 3rem;
    background: $white;
    box-shadow: rgba(0, 0, 0, 0.15) 2px 6px 10px;
    margin-bottom: 30px;
  }

  .fields-form.card {
    padding-bottom: 5rem;
  }

  .section {
    margin-bottom: 20px;
  }

  .user {
    table {
      td:not(:first-child) {
        padding-left: 20px;
      }

      margin-bottom: 10px;
    }
  }

  .resource-name {
    font-size: 18px;
  }
}