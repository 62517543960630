.navbar-default .navbar-brand {
  color: $purple;
  
  &:hover {
    color: $purple;
  }
}

.navbar-default {
  color: $dark-grey;
  background: $white;
  border-color: darken($white, 5%);
  margin-bottom: 0;
  border-radius: 0px;

  .container {
    margin-left: 0;
    margin-right: 0;
    width: 100%;

    .navbar-header {
      margin-right: 40px;
    }
  }
  
  &:hover {
    color: $dark-grey;
    background: $white;
  }
}

.navbar-default .navbar-nav > li > a {
  color: $dark-grey;
  background-color: $white;

  &:focus {
    color: $dark-grey;
    background-color: $white;
  }

  &:hover {
    color: $green;
    background-color: $white;
  }
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:focus {
  color: $green;
  background-color: $white;
}

.navbar-default .navbar-nav > .active > a:hover {
  color: $green;
  background: $white;
}

.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:focus, .navbar-default .navbar-nav>.open>a:hover {
  color: $green;
  background-color: $white;
}

// btn-primary
.btn-primary {
  color: $white;
  background-color: $blue;
  border-color: darken($blue, 5%);

  svg {
    fill: $white;
  }
}

.btn-primary:hover, .btn-primary[disabled]:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open > .dropdown-toggle.btn-primary {
  color: $white;
  background-color: darken($blue, 5%);
  border-color: darken($blue, 5%);

  svg {
    fill: $white;
  }
}

.btn-primary:active, .btn-primary.active, .btn-primary:active:focus, .btn-primary.active:focus, .btn-primary:active:hover, .btn-primary.active:hover {
  color: $white;
  background-color: darken($blue, 7%);
  border-color: darken($blue, 5%);

  svg {
    fill: $white;
  }
}

// btn-success
.btn-success {
  color: $white;
  background-color: $green;
  border-color: darken($green, 5%);

  svg {
    fill: $white;
  }
}

.btn-success:hover, .btn-success[disabled]:hover, .btn-success:focus, .btn-success:active, .btn-success.active, .open > .dropdown-toggle.btn-success {
  color: $white;
  background-color: darken($green, 5%);
  border-color: darken($green, 5%);

  svg {
    fill: $white;
  }
}

.btn-success:active, .btn-success.active, .btn-success:active:focus, .btn-success.active:focus, .btn-success:active:hover, .btn-success.active:hover {
  color: $white;
  background-color: darken($green, 7%);
  border-color: darken($green, 5%);

  svg {
    fill: $white;
  }
}

///
#tooltip > .tooltip-inner {
  background-color: $dark-blue;
  border: 1px solid $dark-blue;
}

#tooltip.right > .tooltip-arrow {
  border-right: 5px solid $dark-blue;
}

a.list-group-item, button.list-group-item {
  color: white;
  background: transparent;
  border: none;

  svg {
    fill: $white;
  }

  &:hover {
    color: $dark-grey;
    background-color: $white;

    svg {
      fill: $dark-grey;
    }
  }
}

.btn:focus,.btn:active:focus,.btn.active:focus,
.btn.focus,.btn:active.focus,.btn.active.focus {
  outline: none;
  box-shadow: none;
}

.rbt-token {
  background-color: $light-blue;
  color: $black;
}

.dropdown-menu > li > a, .dropdown-menu > .active > a, .dropdown-menu > .active > a:focus {
  color: $dark-grey;
  background: $white;
}

.dropdown-menu > li > a:hover, .dropdown-menu > .active > a:hover {
  color: $white;
  background-color: $dark-grey;
}

.alert {
  margin: 20px;
}

.alert-success {
  color: $green;
  background-color: lighten($green, 15%);
  border-color: lighten($green, 5%);
}

.alert-danger {
  color: $purple;
  background-color: lighten($purple, 45%);
  border-color: lighten($purple, 35%);
}

.label-primary {
  background-color: $blue;
}

.label-success {
  background-color: $green;
}

.nav.nav-pills {
  margin-bottom: 10px;
}

.nav-pills > li.active >a, .nav-pills > li.active > a:focus, .nav-pills > li.active > a:hover {
  color: $white;
  background-color: $blue;
}

.form-control {
  background: $white;
  box-shadow: none;

  &:focus {
    border-color: $blue;
    box-shadow: none;
  }
}