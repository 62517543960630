html, body {
  background: $porcelain;
  color: $dark-grey;
  font-weight: 400;
	font-family: Montserrat, "Open Sans", Helvetica, Arial, sans-serif;

	height: 100%;
  margin: 0;
  padding: 0;
}

#react-root {
	min-height: 100%;
  position: relative;	
}

#wrapper {
	padding-bottom: 60px;
}

.full-height {
  height: 100%;
}

.center {
  text-align: center;
}

a {
	color: $blue;
}

.form-control {
	background: $lighter-grey;
	border: 1px solid $light-grey;
}

.thin {
	/* Montserrat Thin = 100 */
	font-weight: 100;
	font-family: Montserrat-Thin, "Open Sans", Helvetica, Arial, sans-serif;
}

.extralight {
	/* Montserrat Extra Light = 200 */
	font-weight: 200;
	font-family: Montserrat-Extra-Light, "Open Sans", Helvetica, Arial, sans-serif;
}


.light {
	/* Montserrat Light = 300 */
	font-weight: 300;
	font-family: Montserrat-Light, "Open Sans", Helvetica, Arial, sans-serif;
}


.regular {
	/* Montserrat Regular = 400 */
	font-weight: 400;
	font-family: Montserrat-Regular, "Open Sans", Helvetica, Arial, sans-serif;
}


.medium {
	/* Montserrat Medium = 500 */
	font-weight: 500;
	font-family: Montserrat-Medium, "Open Sans", Helvetica, Arial, sans-serif;
}


.semibold {
	/* Montserrat Semi-bold = 600 */
	font-weight: 600;
	font-family: Montserrat-Semi-Bol, "Open Sans", Helvetica, Arial, sans-serif;
}


.bold {
	/* Montserrat Bold = 700 */
	font-weight: 700;
	font-family: Montserrat-Bold, "Open Sans", Helvetica, Arial, sans-serif;
}


.extrabold {
	/* Montserrat Extra Bold = 800 */
	font-weight: 800;
	font-family: Montserrat-Extra-Bold, "Open Sans", Helvetica, Arial, sans-serif;
}


.black {
	/* Montserrat Black = 900 */
	font-weight: 900;
	font-family: Montserrat-Black, "Open Sans", Helvetica, Arial, sans-serif;
}

.version {
  font-size: 12px;
  padding-left: 15px;
}

.non-overlay-btn, .react-file-reader {
	.btn {
		margin-left: 5px;
	}
}

.alerts {
	position: sticky;
	top: 20px;
	z-index: 10;
}