.footer {
  color: $white;
  background-color: $blue;
	border-top: 1px solid darken($blue, 5%);
	padding: 20px 40px;
	position: absolute;
	left: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  text-align: center;
  
  ul {
    list-style-type: none;
    padding: 0;
  }

  a {
    color: white;
  }
}