.login-page, .forgot-password-page, .reset-password-page {
  padding-top: 10vw;
  
  .logo {
    margin-bottom: 2rem;
    max-width: 250px;
    text-align: center;
  }

  .login-box {
    background: $white;
    border: 1px solid $porcelain;
    border-radius: 10px;
    padding: 3rem;
    box-shadow: rgba(0, 0, 0, 0.15) 2px 6px 10px;
  }

  .admin-text {
    color: $purple;
    margin-bottom: 20px;
    font-size: 20px;
    font-style: italic;
  }
}